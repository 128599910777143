<template>
  <MainLayout>
    <router-view />
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import { inject } from "vue";

export default {
  name: "Application",
  components: {
    MainLayout,
  },

  async created() {
    const auth = inject("Auth");
    const { getTokenSilently } = auth;
    const token = await getTokenSilently();
    localStorage.setItem("token", token);
  },
};
</script>

<style>
h1 {
  font-size: 22px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
</style>
