<template>
  <div class="the-header">
    <div class="the-header-left-block">
      <slot name="logo"></slot>
      <button class="the-header-app-name" @click="$emit('logoclick')">
        {{ appName }}
      </button>
    </div>

    <div class="the-header-avatar">
      <Avatar :image="userImage" shape="circle" @click="toggle" />
      <Menu id="overlay_menu" ref="menu" :model="userOptions" :popup="true" />
    </div>
  </div>
</template>

<script>
import Avatar from "primevue/avatar";
import Menu from "primevue/menu";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, inject, computed } from "vue";

export default {
  name: "TheHeader",
  components: {
    Avatar,
    Menu,
  },
  inject: ["Auth"],
  props: {
    appName: {
      type: String,
      default: "",
    },
  },
  setup() {
    const selectedAccount = ref();
    const menu = ref();
    const auth = inject("Auth");
    const store = useStore();
    const router = useRouter();

    const userImage = auth.user.value.picture;

    const accounts = computed(() => store.state.accounts.accounts);

    const onAccountSelect = (event) => {
      const { value: account } = event;
      router.push({
        name: "accounts.account.view",
        params: { accountId: account.id },
      });
    };

    const userOptions = ref([
      {
        items: [
          {
            label: "Log out",
            icon: "pi pi-sign-out",
            command: () => {
              auth.logout();
            },
          },
        ],
      },
    ]);

    const toggle = (event) => {
      menu.value.toggle(event);
    };

    return {
      onAccountSelect,
      selectedAccount,
      userOptions,
      toggle,
      menu,
      accounts,
      userImage
    };
  },
};
</script>

<style>
.the-header {
  padding: 1rem;
  border-bottom: 1px solid #dadce0;
  display: flex;
  justify-content: space-between;
}

.the-header-left-block {
  display: flex;
  align-items: center;
}

.the-header-app-name {
  border: none;
  font-size: 22px;
  margin-left: 1rem;
  margin-right: 1rem;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: #5f6368;
  background-color: white;
  cursor: pointer;
}

.divider {
  border-left: 1px solid #dadce0;
  margin-left: 1rem;
  margin-right: 1rem;
}

.the-header-avatar {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>