<template>
  <h1>Accounts</h1>
  <Card>
    <template #content>
      <Message v-if="!accounts.length" :closable="false">
        No accounts found</Message
      >
      <DataTable
        :value="accounts"
        responsiveLayout="scroll"
        v-else
        @row-click="onRowClick"
        selectionMode="single"
      >
        <Column field="name" header="Name"></Column>
      </DataTable>
    </template>
    <template #footer>
      <Button icon="pi pi-check" label="Create" @click="onCreate()" />
    </template>
  </Card>
</template>

<script>
import Card from "primevue/card";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Message from "primevue/message";
import Column from "primevue/column";
import { useStore } from "vuex";
import { computed } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "OverviewIndex",
  components: {
    Card,
    Button,
    DataTable,
    Message,
    Column,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const onRowClick = (event) => {
      const { data: account } = event;

      router.push({
        name: "accounts.account.view",
        params: { accountId: account.id },
      });
    };

    const onCreate = () => {
      router.push({
        name: "accounts.create",
      });
    };

    return {
      accounts: computed(() => store.state.accounts.accounts),
      onRowClick,
      onCreate,
    };
  },
};
</script>

<style scoped>
tr {
    cursor: pointer !important;
    background-color: tomato;
}
</style>