import { createStore, createLogger } from 'vuex'
import main from './modules/main'
import accounts from './modules/accounts'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    main,
    accounts
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})