async function auth(config) {

  const accessToken = localStorage.getItem('token');

  if (accessToken) {
    const headers = { Authorization: `Bearer ${accessToken}` };

    Object.assign(config.headers, headers);
  }

  return config;
}

export default auth;
