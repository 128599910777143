import { create } from 'axios'
import auth from './interceptors/auth';

const baseURL = process.env.VUE_APP_BACKEND_URL

const client = create({
  baseURL,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
  responseType: 'json',
  responseEncoding: 'utf8',
})

client.interceptors.request.use(auth);

export default client