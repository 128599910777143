import { reactive } from 'vue';
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

const getAccount = () => {

  const state = reactive({
    name: '',
  });

  const rules = {
    name: { required },
  };

  const v$ = useVuelidate(rules, state);

  return v$
}

export {
  getAccount
};