import { createRouter, createWebHistory } from 'vue-router'
import AccountsRoot from '../views/accounts/AccountsRoot.vue'
import AccountsIndex from '../views/accounts/AccountsIndex.vue'
import AccountsCreate from '../views/accounts/AccountsCreate.vue'
import AccountsAccount from '../views/accounts/AccountsAccount.vue'
import AccountsAccountView from '../views/accounts/AccountsAccountView.vue'
import {Auth0} from "@/auth";


const routes = [
  {
    path: '/',
    name: 'accounts',
    component: AccountsRoot,
    beforeEnter: Auth0.routeGuard,
    children:[
      {
        path:'create',
        name: 'accounts.create',
        component: AccountsCreate
      },
      {
        path:':accountId',
        name: 'accounts.account',
        component: AccountsAccount,
        children: [
          {
            path:'',
            name: 'accounts.account.view',
            component: AccountsAccountView
          },
        ]
      },

      {
        path:'',
        name: 'accounts.index',
        component: AccountsIndex,
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
