<template>
  <h1>New account</h1>
  <Card>
    <template #content>
      <AccountForm
        :account="accountModel"
        :submitted="submitted"
        v-model:name="accountModel.name.$model"
      />
    </template>
    <template #footer>
      <Button label="Create" @click="onCreate" />
    </template>
  </Card>
</template>

<script>
import { useRouter } from "vue-router";
import { createAccount } from "@/services";
import { ref } from "vue";
import AccountForm from "@/components/AccountForm.vue";
import { getAccount } from "@/models/account";
import Card from "primevue/card";
import Button from "primevue/button";
export default {
  components: {
    AccountForm,
    Card,
    Button,
  },
  setup() {
    const router = useRouter();
    const accountModel = getAccount();
    const submitted = ref(false);

    const onCreate = async () => {
      submitted.value = true;

      if (accountModel.$invalid) {
        return;
      }

      const {
        data: { data: account },
      } = await createAccount({name:accountModel.value.name.$model});

      if (account) {
        router.push({
          name: "accounts.account.view",
          params: { accountId: account.id },
        });
      }
    };

    return {
      accountModel,
      submitted,
      onCreate,
    };
  },
};
</script>