<template>
  <router-view />
</template>

<script>

import { fetchSubscriptions } from "@/services";
import store from "@/store";

const fetchData = async (to) => {
  const {data} = await fetchSubscriptions();

  const accountId = to.params.accountId
  const account = store.state.accounts.accounts.find(a => a.id === accountId);

  to.meta.subscriptions = data.data
  to.meta.account = account
};

export default {
  name: "AccountsAccount",

  async beforeRouteEnter(to, from, next) {
    await fetchData(to);
    next();
  },

  async beforeRouteUpdate(to, from, next) {
    await fetchData(to);
    next();
  },
};
</script>