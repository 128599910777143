/* eslint-disable no-unused-vars */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import 'normalize.css'
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';

import {Auth0} from "@/auth";

async function init() {
  const AuthPlugin = await Auth0.init({
    onRedirectCallback: (appState) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname,
      )
    },
    clientId: process.env.VUE_APP_AUTH0_CLIENT_KEY,
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    redirectUri: window.location.origin,
  });
  const app = createApp(App).use(AuthPlugin)
    .use(router)
    .use(store)
    .use(PrimeVue)
    .mount('#app');
}

init();
