import client from '../client';

function fetchAccounts() {
  const method = 'get';
  const url = `/accounts`;

  return client({ method, url });
}

function createAccount(account) {
  const method = 'post';
  const url = `/accounts`;
  const data = account

  return client({ method, url, data});
}

export {
  fetchAccounts,
  createAccount,
};