<template>
  <router-view />
</template>

<script>
import { fetchAccounts } from "@/services";
import store from "@/store";

const fetchData = async () => {
  const {data} = await fetchAccounts();
  store.commit('accounts/SET_ACCOUNTS', data.data)
};

export default {
  async beforeRouteEnter(to, from, next) {
    await fetchData();
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    await fetchData();
    next();
  },
};
</script>