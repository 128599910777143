<template>
  <div class="p-field">
    <div class="p-float-label">
      <InputText
        id="name"
        :modelValue="name"
        @update:modelValue="$emit('update:name', $event)"
        :class="{ 'p-invalid': account.name.$invalid && submitted }"
      />
      <label
        for="name"
        :class="{ 'p-error': account.name.$invalid && submitted }"
        >Name*</label
      >
    </div>
    <small
      v-if="
        (account.name.$invalid && submitted) || account.name.$pending.$response
      "
      class="p-error"
      >{{ account.name.required.$message.replace("Value", "Name") }}</small
    >
  </div>
</template>

<script>
import InputText from "primevue/inputtext";

export default {
  name: "AccountForm",
  components: {
    InputText,
  },
  emits: ["update:name"],
  props: {
    account: Object,
    name: String,
    submitted: Boolean,
  },
};
</script>