<template>
  <TheHeader app-name="YCCaster" @logoclick="onLogoClick">
    <template v-slot:logo>
      <img
        alt="logo"
        src="https://yccaster.com/logo.svg"
        height="32"
        @click="onLogoClick"
      />
    </template>
  </TheHeader>
  <div class="main">
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import { useRouter } from "vue-router";

export default {
  name: "MainLayout",
  components: {
    TheHeader,
  },
  setup() {
    const router = useRouter();

    const onLogoClick = () => {
      router.push({ name: "accounts.index" });
    };

    return {
      onLogoClick,
    };
  },
};
</script>

<style scoped>
.main {
  padding: 1rem;
  background-color: #f8f9fa;
}

.content {
  max-width: 1024px;
  margin-right: auto;
  margin-left: auto;
}
</style>