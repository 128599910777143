<template>
  <h1>{{ account.name }}</h1>
  <Card>
    <template #title> Subscriptions </template>
    <template #content>
      <Message v-if="!subscriptions.length" :closable="false">
        No subscriptions found</Message
      >
    </template>
    <template #footer>
      <Button class="p-button-sm" @click="onSubscribe">Add</Button>
    </template>
  </Card>
</template>

<script>
import { createSubscription } from "@/services";
import { useRoute } from "vue-router";
import Message from "primevue/message";
import Button from "primevue/button";
import Card from "primevue/card";

export default {
  name: "AccountsAccountView",

  components: {
    Message,
    Button,
    Card,
  },

  setup() {
    const { subscriptions, account } = useRoute().meta;
    const { accountId } = useRoute().params;

    const onSubscribe = async () => {
      const { data } = await createSubscription(accountId);

      if (data.data.redirect_url) {
        window.location.replace(data.data.redirect_url);
      }
    };
    return {
      account,
      subscriptions,
      onSubscribe,
    };
  },
};
</script>