import client from '../client';

function fetchSubscriptions() {
  const method = 'get';
  const url = `/subscriptions`;

  return client({ method, url });
}

function createSubscription(accountId) {
    const method = 'post';
    const url = `/subscriptions`;

    const data = {account_id: accountId}
  
    return client({ method, url, data});
  }

export {
  fetchSubscriptions,
  createSubscription,
};